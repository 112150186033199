export default [
  {
    title: 'Quality',
    slug: 'quality',
    description:
      "Accounting and taxes require a high attention to detail – and we are the best. We don't settle for mediocrity, but are constantly striving for producing the highest quality financial statements and tax returns possible while also keeping prices equal to our competitors."
  },
  {
    title: 'Trustworthy',
    slug: 'trustworthy',
    description:
      "We believe in following the AICPA's vision on Integrity: “We are committed to upholding the highest ethical standards to maintain trust and credibility with colleagues, members and the public.”"
  },
  {
    title: 'Experience',
    slug: 'experience',
    description:
      "We have years of experience in both public and private accounting. Our partners each graduated with a Master's of Accountancy from some of the top schools in the country. We specialize in helping small businesses obtain their owner's dreams."
  }
];
