import React from 'react';
import Seo from '../components/Seo';
import Button from '../components/Button';
import Layout from '../components/layout/Layout';
import CallToAction from '../components/CallToAction';
import Card from '../components/Card';
import SplitSection from '../components/SplitSection';

import Hamptons from '../images/hamptons.jpg';
import Timp from '../svg/timp.svg';

import aboutData from '../data/about-data';
import teamData from '../data/team-data';

export default () => (
  <Layout>
    <Seo title="About Us" page="about" />
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="flex justify-center lg:justify-start lg:w-1/2">
          <img src={Timp} alt="logo" className="w-full mb-6 md:w-1/2 lg:mb-0 lg:w-2/3" />
        </div>
        <div className="text-center lg:text-right lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">Who We Are</h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">High Quality CPAs</p>
          <p className="mt-8 md:mt-12">
            <Button size="lg">Contact Us</Button>
          </p>
          <p className="mt-4 text-gray-600">Let us know how we can help you</p>
        </div>
      </div>
    </section>
    <section id="our-firm" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Our Firm</h2>
        <p className="mt-8 px-10 text-xl">
          You don&apos;t need an auditor, a tax professional, or a consultant. You need a financial
          partner. You need someone who can see past the numbers and provide meaningful insight into
          your unique circumstances.
        </p>
        <p className="mt-8 text-xl">See what makes us different:</p>
        <div className="flex flex-col flex-wrap sm:flex-row sm:-mx-3 mt-12">
          {aboutData.map(item => (
            <div key={item.slug} className="flex flex-1 md:flex-none md:w-1/3 px-3">
              <Card>
                <p className="font-semibold text-xl">{item.title}</p>
                <p className="mt-4">{item.description}</p>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </section>
    <section id="team">
      {teamData.map(person => (
        <SplitSection
          id={person.slug}
          key={person.slug}
          primarySlot={
            <div>
              <h3 className="text-3xl font-semibold leading-tight">{person.name}</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">{person.description}</p>
            </div>
          }
          secondarySlot={<img src={Hamptons} alt="mike-hampton" className="m-auto md:mr-0 w-3/4" />}
        />
      ))}
    </section>
    <CallToAction />
  </Layout>
);
