import Hamptons from '../images/hamptons.jpg';

export default [
  {
    name: 'Mike Hampton, CPA',
    role: 'Owner',
    image: Hamptons,
    slug: 'mike-hampton',
    description:
      "As the owner and founder of Timpanogos Accounting I believe that accounting and analytics should be simplified as much as possible and that nobody should pay more taxes than legally obligated. With over 10 years of experience and a Master's degree in Accounting from BYU, I have the knowledge and experience to help small businesses with their accounting, tax, and analytic needs. On a personal note, I enjoy spending time with my wife, Brooke, and our five children. We love hiking, camping, fishing, and playing sports."
  }
];
